import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CapturumInputNumberComponent, InputNumberButtonLayout, InputNumberMode } from '@capturum/ui/input-number';
import { InputWidgetBaseComponent } from '@core/bases/input-widget-base/input-widget-base.component';
import { CompanyCodesEnum } from '@core/enums/company-codes.enum';
import { AppRoutes } from '@core/enums/routes.enum';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { TableUpdateType } from '@core/enums/table-type.enum';
import { InputMethodHandlingType } from '@core/models/input-method-handling-type.model';
import { ShowConfirmationDialog, UpdateChangedItem } from '@store/general/general.actions';
import { GeneralSelectors } from '@store/general/general.selectors';
import { filter, first, takeUntil } from 'rxjs/operators';
import { BaseKey } from '@core/enums/base-key.enum';
import { MapItem } from '@capturum/auth';

@Component({
  selector: 'app-list-renderer-widget-input',
  templateUrl: './list-renderer-widget-input.component.html',
  styleUrls: ['./list-renderer-widget-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListRendererWidgetInputComponent extends InputWidgetBaseComponent implements OnInit, AfterViewInit {
  public inputNumberMode = InputNumberMode;
  public fractionDigits: number;
  public initialFractionDigits: number;
  public prefix: string;
  public preFilledOnFocus: string;
  public salesOrderId: string;
  public mode = this.inputNumberMode.decimal;
  public min: number;
  public inProgressStatusId: { status_base_data_value_id: string };
  public disabled = false;
  public hideInput = false;
  public isMobile = false;
  public isSalesOrder = false;
  public isPurchaseOrder = false;
  public statusToShowConfirmation = false;
  public isFirstTab = false;
  public grayedField = false;
  public negativeInputs = [
    `${AppRoutes.purchaseOrderCost}/${AppRoutes.percentage}`,
    `${AppRoutes.purchaseOrderCost}/${AppRoutes.price}`,
    `${AppRoutes.salesOrderCost}/${AppRoutes.percentage}`,
    `${AppRoutes.salesOrderCost}/${AppRoutes.price}`,
    `${AppRoutes.supplierCost}/${AppRoutes.percentage}`,
    `${AppRoutes.supplierCost}/${AppRoutes.price}`,
    `${AppRoutes.stockInLineEdit}/${AppRoutes.submitStock}`,
  ];

  public showButtons = false;
  public buttonLayout = InputNumberButtonLayout.horizontal;

  public fieldNames = ['quantity', 'allocate_amount'];

  @ViewChild(CapturumInputNumberComponent)
  public inputNumberComponent: CapturumInputNumberComponent;

  public ngOnInit(): void {
    this.isMobile = this.breakPointService.getScreenSize() === ScreenSize.mobile;
    this.isSalesOrder = this.router.url.includes('sales-order');
    this.isPurchaseOrder = this.router.url.includes('purchase-order');
    this.isFirstTab = this.router.url.includes('tab=1');

    if (this.isSalesOrder) {
      this.salesOrderId = this.activatedRoute.snapshot.paramMap.get('sales_order_id');

      const salesOrderStatuses = this.salesOrderService.salesOrderStatuses$.getValue();
      const currentOrderStatusId = this.salesOrderService.salesOrder$.getValue()?.status_base_data_value_id;

      const inProgressStatus = salesOrderStatuses.find(({ key }: MapItem) => {
        return key === BaseKey.SalesOrderStatusInProgress;
      });
      const orderStatus: BaseKey = salesOrderStatuses.find(({ value }: MapItem) => {
        return value === currentOrderStatusId;
      })?.key as BaseKey;

      this.inProgressStatusId = {
        status_base_data_value_id: inProgressStatus?.value,
      };

      if ([BaseKey.SalesOrderStatusHandled, BaseKey.SalesOrderStatusReady].includes(orderStatus)) {
        this.statusToShowConfirmation = true;
      }
    }

    this.setFieldDecimals();

    if (this.field === 'unit') {
      this.hideUnitField();
    }

    if (this.field === 'submit_price') {
      this.listenForSubmitStockValue();
    }

    this.fractionDigits = this.decimals ?? 2;
    this.initialFractionDigits = this.fractionDigits;

    super.ngOnInit();
    this.prefillValue();
  }

  public ngAfterViewInit(): void {
    if (this.showButtons && this.inputNumberComponent?.showButtons) {
      const inputNumberInputEl = this.inputNumberComponent['inputNumber'].input.nativeElement as HTMLInputElement;

      // input needs to be readonly in order to disable keyboard on mobile.
      inputNumberInputEl.readOnly = true;
    }
  }

  public onKeyEnterEvent(): void {
    if (this.method === InputMethodHandlingType.store) {
      this.inputNumberComponent['inputNumber']?.el?.nativeElement?.querySelector('input')?.blur();
    }
  }

  public setFocus(focused: boolean): void {
    this.min =
      (this.endpoint.url &&
        this.negativeInputs.some((input) => {
          return this.endpoint.url.includes(input);
        })) ||
      this.field === 'count'
        ? null
        : 0;

    super.setFocus(focused);

    if (this.prefix) {
      this.fractionDigits = focused ? 0 : this.initialFractionDigits;

      if (this.control.value || this.control.value === 0) {
        const valueInCents = this.control.value * 100;
        const valueInBancnotes = this.control.value / 100;

        this.control.setValue(focused ? valueInCents : valueInBancnotes, {
          emitEvent: !focused,
        });
      }

      if (focused) {
        this.selectTextOnFocus();
      } else {
        this.inputValueChange.next(this.control.value);
      }
    } else if (!focused) {
      if (
        (this.field === 'quantity' || this.field === 'unit') &&
        this.isSalesOrder &&
        this.isFirstTab &&
        this.statusToShowConfirmation &&
        this.checkForValueChange(this.control.value) &&
        this.item[this.field] < this.control.value
      ) {
        this.store.dispatch(
          new ShowConfirmationDialog({
            header: this.translateService.instant('confirm.title'),
            message: this.translateService.instant('demooij.alert.create-pick-order'),
            accept: () => {
              this.inputWidgetService.changeStatus(this.salesOrderId, this.inProgressStatusId).subscribe(() => {
                const message = this.translateService.instant('demooij.toast.success.order-status-updated');

                this.toastService.success(message);

                this.inputValueChange.next(this.control.value);
              });
            },
            reject: () => {
              this.inputValueChange.next(this.control.value);
            },
          }),
        );

        return;
      }

      if (
        this.field === 'quantity' &&
        this.isPurchaseOrder &&
        this.checkForValueChange(this.control.value) &&
        this.isFirstTab &&
        this.purchaseOrderService.isAllocatedToRide
      ) {
        this.purchaseOrderService.openConfirmationDialog(() => {
          this.inputValueChange.next(this.control.value);
        });

        return;
      }

      if (this.field === 'split_quantity') {
        if (this.control.value > this.item.quantity) {
          this.toastService.error(
            this.translateService.instant('demooij.exceptions.input-greater-than-existing-amount', {
              input: this.control.value,
              amount: this.item.quantity,
            }),
          );
          this.control.setValue(0);
          this.control.updateValueAndValidity();
        }

        if (this.checkForValueChange(this.control.value)) {
          this.store.dispatch(
            new UpdateChangedItem(this.field, this.index, {
              ...this.item,
              [this.field]: this.control.value,
            }),
          );
        }
      } else {
        this.inputValueChange.next(this.control.value);
      }
    }
  }

  public setFieldDecimals(): void {
    const isCashCarryCompany =
      this.store.selectSnapshot(GeneralSelectors.getActiveCompany)?.code === CompanyCodesEnum.cashcarry;

    if (this.fieldNames.includes(this.field) && this.item?.product?.amount_per_package === 1 && isCashCarryCompany) {
      this.decimals = 0;
    }
  }

  public selectTextOnFocus(): void {
    setTimeout(() => {
      // to obtain select on focus effect
      const focusedIndex = this.store.selectSnapshot(GeneralSelectors.focusedRowIndex);
      const focusedFieldName = this.store.selectSnapshot(GeneralSelectors.focusedFieldName);

      if (focusedIndex === this.index && focusedFieldName === this.field) {
        this.inputNumberComponent['inputNumber']?.el?.nativeElement?.querySelector('input')?.select();
      }
    });
  }

  public hideUnitField(): void {
    this.hideInput = this.item?.product?.amount_per_package === 1;
    this.grayedField = this.hideInput && this.isMobile;
  }

  public listenForSubmitStockValue(): void {
    this.hideInput = this.item.submit_stock && this.item.submit_stock <= this.item.counted_stock;

    this.inputWidgetService.changesInTheRow
      .asObservable()
      .pipe(
        filter((changes) => {
          return (
            changes[this.uniqueProperty] === this.item[this.uniqueProperty] &&
            Object.keys(changes.changedFields)?.includes('submit_stock')
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((changes) => {
        const submitStock = parseInt(`${changes.changedFields?.submit_stock || 0}`);
        const stock = parseInt(`${this.item.counted_stock}`);

        this.hideInput = submitStock === 0 || (submitStock > 0 && submitStock <= stock);

        this.cdr.detectChanges();
      });
  }

  public resetValue(value: string | number): void {
    this.toastService.error(this.translateService.instant('demooij.exceptions.quantity-and-unit-0'));
    this.ngZone.onStable.pipe(first()).subscribe(() => {
      this.control.setValue(value, { emitEvent: false });
      this.cdr.detectChanges();
    });
  }

  private prefillValue(): void {
    const value = this.preFilledOnFocus && this.item[this.preFilledOnFocus] && +this.item[this.preFilledOnFocus];

    if (value && value > 0 && !this.control.value) {
      this.control.setValue(value);
      this.inputValueChange.next(this.control.value);
    }
  }

  public handleValueChange(newValue: string | number): void {
    const currentItem = this.store.selectSnapshot(GeneralSelectors.getSavedListRenderData)?.find((item) => {
      return item[this.uniqueProperty] === this.item?.[this.uniqueProperty];
    });

    if (this.field === 'unit') {
      if (newValue >= this.item.product.amount_per_package && this.tableType !== TableUpdateType.instant) {
        this.toastService.error(this.translateService.instant('demooij.exceptions.unit-column-must-be-lower'));
        this.ngZone.onStable.pipe(first()).subscribe(() => {
          this.control.setValue(currentItem?.unit ? currentItem?.unit : 0);
          this.cdr.detectChanges();
        });

        return;
      }

      if (newValue === 0 && currentItem?.quantity === 0) {
        this.resetValue(currentItem?.unit);
      }
    }

    if (this.field === 'quantity' && newValue === 0 && currentItem?.unit === 0) {
      this.resetValue(currentItem?.quantity);
    }

    super.handleValueChange(newValue);
  }
}
