import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { Observable } from 'rxjs';
import { ApiSingleResult } from '@capturum/api/lib/interfaces/api.interface';
import { PurchaseOrderProduct } from '@core/models/purchase-order-product.model';
import { CreditNote } from '@core/interfaces/credit-note.interface';
import { responseData } from '@capturum/builders/core';
import { ShowConfirmationDialog } from '@store/general/general.actions';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService {
  public isAllocatedToRide: boolean;

  protected endpoint = '/purchase-order';

  constructor(
    private readonly apiHttp: ApiHttpService,
    protected readonly store: Store,
    protected readonly translateService: TranslateService,
  ) {}

  public copyPurchaseOrder(purchaseOrderId: string): Observable<void> {
    return this.apiHttp.get(`${this.endpoint}/${purchaseOrderId}/stock-reallocation`);
  }

  public downloadPdf(purchaseOrderId: string): Observable<Blob> {
    return this.apiHttp.get(`${this.endpoint}/${purchaseOrderId}/download`, {
      responseType: 'blob',
    });
  }

  public sendConfirmation(purchaseOrderId: string): Observable<any> {
    return this.apiHttp.get(`${this.endpoint}/${purchaseOrderId}/send`);
  }

  public removeNoQuantityProducts(purchaseOrderId: string): Observable<void> {
    return this.apiHttp.post(`${this.endpoint}/${purchaseOrderId}/remove-products-no-quantity`, {});
  }

  public getProductSupplierPrice(purchaseOrderId: string, productId: string): Observable<any> {
    return this.apiHttp.get(`${this.endpoint}/${purchaseOrderId}/product/${productId}/get-latest-price`);
  }

  public getCreditnotePDF(purchaseOrderId: string, data: { products: CreditNote[] }): Observable<any> {
    return this.apiHttp.post(`/purchase-order-product/${purchaseOrderId}/download-credit-note`, data, {
      responseType: 'blob',
    });
  }

  public getTotal(purchaseOrderId: string): Observable<
    ApiSingleResult<{
      products: Record<string, string>;
      costs: Record<string, string>;
      creditors: Record<string, object>;
    }>
  > {
    return this.apiHttp.get(`${this.endpoint}/${purchaseOrderId}/summary`);
  }

  public bulkStoreProducts(
    id: string,
    data: Pick<PurchaseOrderProduct, 'product_id' | 'quantity' | 'purchase_price'>[],
  ): Observable<{ data: any; meta: any }> {
    return this.apiHttp.post(`${this.endpoint}/${id}/bulk-store-products`, data);
  }

  public getAllocatedToRide(id: string): Observable<{ is_allocated_to_ride: boolean }> {
    return this.apiHttp.get(`${this.endpoint}/${id}/get-allocated-to-ride`).pipe(responseData);
  }

  public openConfirmationDialog(acceptCallback: () => void): void {
    this.store.dispatch(
      new ShowConfirmationDialog({
        header: this.translateService.instant('confirm.title'),
        message: this.translateService.instant('demooij.purchase-order.contact-transporter.message'),
        rejectButtonStyleClass: 'd-none',
        acceptLabel: this.translateService.instant('demooij.btn.confirm'),
        accept: acceptCallback,
      }),
    );
  }
}
